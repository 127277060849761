<template>
    <v-container class="bg-page" fluid fill-height justify-center align-center>
        <v-row justify="center">
            <v-col cols="12" sm="8" md="3" lg="3" xl="true">
                <transition name="fade">
                    <v-card v-show="show" elevation="12">
                        <v-toolbar flat height="100" class="pt-5">
                            <v-img src="/img/logoBlue.png" max-height="40px" contain></v-img>
                        </v-toolbar>
                        <v-card-text class="mx-0 px-0">
                            <v-row class="mx-0 px-0">
                                <v-col class="mx-0 px-0">
                                    <v-col class="my-0 py-2"
                                           style=" font-size: 20px; font-weight:bold;  color: #45649f">
                                        {{ $t('authorization') }}
                                    </v-col>
                                    <v-divider light style="border-width:2px; border-color: #45649f"></v-divider>
                                </v-col>
                                <v-col class="mx-0 px-0 py-7">
                                    <br/>
                                    <v-divider style="border-width:2px;" light></v-divider>
                                </v-col>
                            </v-row>
                        </v-card-text>
                        <v-form @submit.prevent="submit">
                            <ValidationObserver ref="observer" v-slot="{ invalid, validated, passes, validate }">
                                <v-card-text class="px-6">
                                    <v-row>
                                        <v-col cols="12" class="pb-2">
                                            <ValidationProvider ref="login" rules="required"
                                                                v-slot="{ errors, valid }">
                                                <v-text-field v-model="login" type="text"
                                                              :error-messages="errors" :disabled="loading"
                                                              outlined rounded dense :label="$t('login')">
                                                </v-text-field>
                                            </ValidationProvider>
                                        </v-col>
                                        <v-col cols="12" class="py-2">
                                            <ValidationProvider ref="password" rules="required|min:8"
                                                                v-slot="{ errors, valid }">
                                                <v-text-field v-model="password"
                                                              :append-icon="passwordHidden ? 'mdi-eye-off' : 'mdi-eye'"
                                                              :type="passwordHidden ? 'password' : 'text'"
                                                              :error-messages="errors" :disabled="loading"
                                                              @click:append="() => (passwordHidden = !passwordHidden)"
                                                              outlined rounded dense :label="$t('password')"
                                                ></v-text-field>
                                            </ValidationProvider>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                                <v-card-text class="px-6 pb-6 text-right">
                                    <v-btn type="submit" color="primary" :loading="loading" rounded
                                           :disabled="loading || invalid || !validated">
                                        {{ $t('sign_in') }}
                                    </v-btn>
                                </v-card-text>
                            </ValidationObserver>
                        </v-form>
                    </v-card>
                </transition>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>

import {mapGetters} from "vuex"
import {ValidationObserver, ValidationProvider} from "vee-validate"
import {mask} from 'vue-the-mask'

export default {
    name: "Login",
    directives: {
        mask
    },
    components: {
        ValidationProvider,
        ValidationObserver
    },
    data() {
        return {
            login: null,
            password: null,
            remember: null,
            passwordHidden: true,
            loading: false,
            show: false,
        };
    },
    computed: {
        ...mapGetters(['lang']),
    },
    mounted() {
        this.show = true
    },
    methods: {
        clear() {
            this.login = this.password = null;
            requestAnimationFrame(() => {
                this.$refs.observ.reset()
            })
        },
        async submit() {
            var _this = this
            this.loading = true;
            await this.$auth
                .login({
                    body: {
                        login: this.login,
                        password: this.password,
                    },
                    fetchUser: true
                })
                .then(res => {
                    if (this.$auth.check() && this.$auth.user() && this.$auth.user().settings) {
                        this.$store.dispatch('setSettings', this.$auth.user().settings)
                    }
                    this.$toastr.success(this.$t('you_have_successfully_logged_in'));
                })
                .catch(err => {
                    if (err && err.status !== 503) {
                        this.$toastr.error(this.$t('you_entered_the_wrong_data'));
                    }
                    if (err && err.body && err.body.message) {
                        if (err && err.status === 503) {
                            this.$toastr.error(this.$t('the_service_is_temporarily_unavailable_please_try_again_later'), this.$t('maintenance_mode'));
                        } else {
                            for (let prop in err.body.errors) {
                                if (hasOwnProperty.call(err.body.errors, prop)) {
                                    if (_this.$refs[prop]) {
                                        _this.$refs[prop].setErrors([
                                            err.body.errors[prop][0]
                                        ])
                                    }
                                }
                            }
                            if (!err.body.errors) {
                                this.$toastr.error(err.body.message)
                            }
                        }
                    }
                });
            this.loading = false;
        }
    }
}
</script>
<style scoped>
.v-sheet.v-card {
    border-radius: 30px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.4) !important;
}
</style>
